<template>
  <v-form ref="form" v-model="valid" :lazy-validation="lazy">
    <v-container>
      <v-row>
        <v-col cols="12" sm="6">
          <v-card class="my-8">
            <v-list class="pa-4">
              <v-text-field
                v-model.trim="model.phone"
                label="전화번호"
                :rules="[rules.required]"
                outlined
                dense
                hide-details="auto"
              />
            </v-list>
            <v-list class="pa-4">
              <v-textarea
                v-model.trim="model.reason"
                label="차단 사유"
                :rules="[rules.required]"
                outlined
                dense
                hide-details="auto"
              />
            </v-list>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-5">
        <v-col cols="auto">
          <v-btn v-if="mode === 'add'" class="mx-4" color="primary" @click="addItem">
            <v-icon small>
              add
            </v-icon>
            <span class="pl-2">등록</span>
          </v-btn>
          <!-- <v-btn v-if="mode === 'edit'" class="mx-4" color="primary" @click="updateItem">
            <v-icon small>
              edit
            </v-icon>
            <span class="pl-2">수정</span>
          </v-btn> -->
          <v-btn
            v-if="mode === 'edit'"
            class="mx-4"
            color="error"
            @click="deleteConfirmSheetView = !deleteConfirmSheetView"
          >
            <v-icon small>
              delete
            </v-icon>
            <span class="pl-2">삭제</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-bottom-sheet v-model="deleteConfirmSheetView" persistent>
      <v-sheet class="text-center" height="250px">
        <div class="py-3">
          정말 삭제하시겠습니까?
          <v-btn class="mx-3" color="primary" @click="deleteItem">
            <span>Y</span>
          </v-btn>
          <v-btn class="mx-3" color="" @click="deleteConfirmSheetView = !deleteConfirmSheetView">
            <span>N</span>
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-form>
</template>
<script>
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

import vueFilePond from 'vue-filepond'
import axios from 'axios'
import Config from '@/config'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview)

export default {
  components: {
    FilePond
  },
  // eslint-disable-next-line vue/prop-name-casing
  props: ['value', 'working_area_idx', 'working_area_detail_idx'],
  data() {
    return {
      model: null,
      desiredSalaryItems: [
        '가격협의',
        '1만원',
        '2만원',
        '3만원',
        '4만원',
        '5만원',
        '6만원',
        '7만원',
        '9만원',
        '10만원',
        '11만원',
        '12만원',
        '13만원',
        '14만원',
        '15만원',
        '16만원',
        '17만원',
        '18만원',
        '19만원',
        '20만원'
      ],
      officeHourItems: [
        { title: '무관', value: 'anytime' },
        { title: '24시간', value: 'allday' },
        { title: '주간12시간', value: 'daytime' },
        { title: '야간12시간', value: 'nighttime' }
      ],
      valid: false,
      lazy: false,
      deleteConfirmSheetView: false,
      mode: 'add',
      mode_text: '',
      uploadFile: [],
      regions: [],
      cities: [],
      setAlarmStatus: '',
      rules: {
        required: value => !!value || '꼭 입력해주세요'
      }
    }
  },
  watch: {
    async working_area_idx() {
      await this.onChangeRegion()
    }
  },
  async created() {
    await this.setup()
  },
  methods: {
    async setup() {
      this.model = this.value
      const { id } = this.$route.params
      if (id === 'add') {
        this.mode = 'add'
        this.mode_text = '등록'
      } else {
        this.mode = 'edit'
        this.mode_text = '수정'

        await axios.get(`${Config.Api.Service.url}/blackuser/all`).then(data => {
          const user = data.data.find(e => e.idx == id)
          if (user) {
            this.model = user
          } else {
            this.$router.push('/blacklist')
          }
        })
      }
    },
    async attachCurrentTime(filename) {
      return `${filename}?${new Date().getTime()}`
    },
    async addItem() {
      const result = await axios.post(`${Config.Api.Service.url}/blackuser`, {
        phone: this.model.phone,
        reason: this.model.reason
      })

      if (result.data.success) {
        this.$bus.$emit('successMessage', '등록되었습니다')
        this.$router.push('/blacklist')
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    },
    async deleteItem() {
      const { id } = this.$route.params
      const result = await axios.delete(`${Config.Api.Service.url}/blackuser/${id}`)

      if (result.data.success) {
        this.$bus.$emit('successMessage', '삭제되었습니다')
        this.$router.push('/blacklist')
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #eeeeee;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}
.filepond--wrapper {
  ::v-deep .filepond--credits {
    display: none;
  }
}
</style>
